<template>
  <div class="advertismentWrapper offerManagement userManagementWrapper">
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="filterRow">
            <b-col md="12" sm="12" class="mb-1">
             <h3 class="card-title">Filters</h3>
            </b-col>
            <!-- <b-col md="3" sm="6" class="mb-0">
              <b-form-group label="User Type">
                <b-form-select
                  :options="checkUserType"
                  v-model="userType"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="3" sm="6" class="mb-0">
              <b-form-group label="Status">
                <b-form-select
                  :options="userStatus"
                  v-model="status"
                  placeholder="Search"
                  style="text-transform:capitalize;"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" style="margin-top: 34px">
              <span
                @click="(userType = 'All'), (status = 'All')"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  color: #17D1C6;
                  font-weight: 500;
                  margin-top: 10px;
                "
              >
                Clear Filter</span
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" v-if="!showSpinner">
        <b-card>
          <b-row>
            <b-col md="9" sm="7" class="my-1">
              <label
                style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 29px;
                  color: #2c2c2c;
                "
                >User List</label
              >
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>

           <b-col class="offerListtableData userDataTable" cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- <template #cell(propertyId)="data">
                  <div style="display: flex; align-items: center;">
                    <img v-if="Math.round(Math.random()) === 0" src="@/assets/images/svg/star_empty.svg" alt="empty_star"/>
                    <img v-else src="@/assets/images/svg/star_filled.svg" alt="filled_star"/>
                    <a :to="`property/${data.item.propertyId}`" style="text-decoration: underline; margin-left: 10px">
                      {{data.item.propertyId}}
                    </a>
                  </div>
                </template>

                <template #cell(property)="data">
                  <div style="display: flex; align-items: center">
                    <img v-if="data.item.photos && data.item.photos.length" width="40px" height="40px" style="object-fit: cover; border-radius: 5px; margin-right: 15px; min-width: 40px;" :src="data.item.photos[0]" alt="property"/>
                    <div>
                      <span style="white-space: nowrap; display: block; color: #17D1C6; font-weight: 500">{{data.item.country}}, {{data.item.state}}.</span>
                      <span>$ {{data.item.askingPrice}}</span>
                    </div>
                  </div>
                </template> -->

                <template #cell(UserId)="data">
                  <div
                    style="white-space: nowrap;cursor:pointer!important"
                    @click="goToDetailPage(data.item)"
                  >
                    {{ data.item.UserId }}
                  </div>
                </template>

                 <template #cell(UserType)="data">
                  <div v-if="data.item.UserType && data.item.UserType.length <=15 "
                    style="white-space: nowrap;"
                  >
                    {{ data.item.UserType }}
                  </div>
                   <div v-else-if="data.item.UserType && data.item.UserType.length > 15"
                    style="white-space: nowrap;"
                  >
                  <div :title="data.item.UserType">
                    {{ data.item.UserType.slice(0,15)+'...' }}
                  </div>
                  </div>
                   <div v-else
                    style="white-space: nowrap;"
                  >
                    N/A
                  </div>
                </template>

                  <template #cell(Status)="data">
                  <div
                    style="white-space: nowrap;"
                    :class="data.item.Status"
                  >
                  <span class="status-area">{{ data.item.Status }}</span>
                  </div>
                </template>
                <template #cell(Action)="data">
                  <div style="display: flex" class="actionImage">
                    <img
                      src="@/assets/images/svg/eye.svg"
                      alt="view user"
                      style="margin-right: 10px;cursor:pointer;"
                      @click="goToDetailPage(data.item)"
                    />
                    <img 
                      src="@/assets/images/svg/edit.svg" 
                      alt="edit user"
                      style="cursor:pointer;"
                      @click="goToEditPage(data.item)"
                    />
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
            <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol " v-if="filteredItems && filteredItems.length">
              <!-- page length -->
             <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ totalRows }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: true,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        // {
        //   key: "UserType",
        //   label: "User Type",
        // },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: "All",
      status: "All",
      priceRangeOptions: [{ text: "All", value: null }, 1, 2, 3, 4, 5, 6, 7, 8],
    };
  },

  created() {
    var self = this;
    self.getUserData().then(() => {
      self.showSpinner = false;
    });
  },

  methods: {
    ...mapActions({ getUserData: "user/userData" }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    commaSeprateUserType(statusArray) {
      var self = this;
      self.checkAllApply.forEach((data) => {
        statusArray.forEach((arr, index) => {
          if (arr == data.key) {
            statusArray[index] = data.displayName;
          }
        });
      });
      return statusArray;
    },
    generateUid(input) {
      let output = '';
      try {
          for (var i = 0, len = input.length; i < len; i++) {
                output += input[i].charCodeAt(0)
          }
          return output.substr(-7);
      } catch (error) {
        console.log(error, "?");
      }
    },
    goToDetailPage(data) {
      var self = this;
      self.$root.$emit("UserDetailName", data.FullName);
      self.$router.push({
        name: "user-management-detail",
        params: { name: data.FullName, id: data.id },
      });
    },
    goToEditPage(data){
      var self = this;
      self.$root.$emit("UserDetailName", data.FullName);
      self.$router.push({
        name: "editUser",
        params: { name: data.FullName, id: data.id },
      });
    }
  },

  computed: {
    ...mapGetters({
      getStatuses: "admin/statusGetter",
      allUserData: "user/allUserData",
      checkAllApply: "admin/checkThatAllApply",
      usersStatus: "admin/userStatus",
    }),
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let arrayOfUserData = [];
      let tmp = self.allUserData;
      try {
        tmp.forEach((data) => {
            if(data.userRole != 'admin'){
              var obj = {
                id: data.id,
                UserId: self.generateUid(data.id),
                FullName: `${data.firstName} ${data.lastName}`,
                CompanyName: data.companyName,
                UserEmail: data.email,
                UserMobileNumber: data.phoneNumber,
                UserType: data.checkThatAllApply
                  ? self.commaSeprateUserType(data.checkThatAllApply).join(",")
                  : "",
                Status: data.status
                  ? self.usersStatus[data.status - 1].displayName
                  : "-",
                Action: "...",
              };
            arrayOfUserData.push(obj);
          }
        });
        if (self.userType != null && self.userType != "All") {
          if (self.userType) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.UserType.toLowerCase().includes(self.userType.toLowerCase())
            );
          }
        }
        if (self.status != null && self.status != "All") {
          if (self.status) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.Status.toLowerCase().includes(self.status.toLowerCase())
            );
          }
        }

        self.totalRows = arrayOfUserData.length;
        self.showSpinner = false;
        return arrayOfUserData;
      } catch {
        self.showSpinner = false;
        console.log("ERROR in get records of userData");
      }
    },
    filteredStatuses() {
      var self = this;
      if (self.getStatuses && self.getStatuses.length) {
        self.statusOptions = [
          { text: "All", value: null },
          ...self.getStatuses.map((x) => {
            return { text: x.displayName, value: x.key };
          }),
        ];
      }
      return self.getStatuses.map((x) => {
        return {
          label: x.displayName,
          key: x.key,
        };
      });
    },
    checkUserType() {
      var self = this;
      let arr = [];
      if (self.checkAllApply.length) {
        arr.push("All");
        self.checkAllApply.forEach((data) => {
          arr.push(data.displayName);
        });
        return arr;
      } else {
        return [];
      }
    },
    userStatus() {
      var self = this;
      // console.log('self.usersStatus',self.usersStatus);
      let arr = [];
      if (self.usersStatus.length) {
        arr.push("All");
        self.usersStatus.forEach((data) => {
          arr.push(data.displayName);
        });
        return arr;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>